import React, { useEffect } from 'react'
import 'twin.macro'

import tw from 'twin.macro'

import SEO from 'components/shared/seo'
import useVisitNY from 'content-queries/mx/visit-ny'
import fireEvent from 'context/tracking/events'
import { AutomaticPopup } from 'mx/automatic-popup'
import {
  AffiliatesReviews,
  HowItWorks,
  MainBanner,
  MainContent,
  TrustedMedia,
} from 'mx/components/new'
import Layout from 'mx/layout'

const VisitNYPage = () => {
  useEffect(() => {
    fireEvent({
      type: 'page_viewed',
      pageTitle: 'Visit New York Studio',
    })
  }, [])

  const { data } = useVisitNY()
  const [
    {
      node: {
        sectionModules: [
          hero,
          reviews,
          media,
          timeless,
          performance,
          innovation,
          howItWorks,
          premium,
          map,
          notIn,
        ],
      },
    },
  ] = data

  return (
    <Layout header={false}>
      <AutomaticPopup />
      <MainContent header dark data={hero} classname={tw`!bg-brown-medium`} />
      <AffiliatesReviews
        data={reviews}
        classname={tw`!bg-gray-light pt-8 lg:pt-12`}
      />
      <TrustedMedia border data={media} classname={tw`!bg-gray-light pt-16`} />
      <MainContent
        inline
        dark
        data={timeless}
        classname={tw`!bg-brown-dark mt-20 lg:mt-32`}
      />
      <MainContent
        inline
        data={performance}
        classname={tw`!bg-brown-light mt-20 lg:mt-32`}
      />
      <MainContent
        inline
        dark
        data={innovation}
        classname={tw`!bg-brown-medium mt-20 lg:mt-32`}
      />
      <HowItWorks dark data={howItWorks} classname={tw`mt-20 lg:mt-32`} />
      <MainBanner data={premium} inline classname={tw`mt-20 lg:mt-32`} />
      <MainContent inline dark data={map} classname={tw` mt-20 lg:mt-32`} />
      <MainBanner data={notIn} classname={tw`!bg-brown-light mt-20 lg:mt-32`} />
    </Layout>
  )
}
export default VisitNYPage

export const Head = () => {
  const { data } = useVisitNY()
  const [
    {
      node: { image },
    },
  ] = data

  return (
    <SEO
      description="Feel the quality of our European cabinetry and speak with our expert consultants at our New York Studio."
      path="/visit-ny"
      title="New York Studio"
      image={
        image?.localFile?.childImageSharp?.gatsbyImageData?.images?.fallback
          ?.src
      }
    />
  )
}
