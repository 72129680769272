import React, { useContext, useEffect } from 'react'

import { Container, Grid } from 'semantic-ui-react'
import styled from 'styled-components'

import fireEvent from 'context/tracking/events'
import SelectIconCard from 'design-system/components/cards/selection/select-icon-card'
import { Breakpoint } from 'styles/app/system'
import { QuizAnswerCategory } from 'types/prospect'
import { QuizContext } from 'views/welcome/prospect/onboarding-homeowner/project-info'
import { StepViewProps } from 'views/welcome/prospect/onboarding-homeowner/project-info/flow/steps'

const GetStartedView = ({ setNextDisabled, setOnNext }: StepViewProps) => {
  const { dispatch, state, navigateWithQS } = useContext(QuizContext)
  const onChange = (identifier: string) => {
    dispatch({
      type: 'toggle_answer_one_per_category',
      answer: {
        category: QuizAnswerCategory.GET_STARTED,
        value: identifier,
      },
    })
  }

  useEffect(() => {
    document.body.scrollTo({ top: 0 })
    window.scrollTo(0, 0)
    const onNext = async () => {
      fireEvent({
        type: 'quiz_step_completed',
        stepName: 'GET STARTED',
      })
    }
    setOnNext(() => onNext)
  }, [])

  useEffect(() => {
    setNextDisabled(
      !state.answers.find((c) => c.category === QuizAnswerCategory.GET_STARTED),
    )
  }, [state])

  return (
    <>
      <Container>
        <StyledGrid stackable>
          <Grid.Row>
            <Grid.Column textAlign="center">
              <h3>
                Get Started for Free.
                <br />
                How would you like to chat?
              </h3>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row></Grid.Row>
          <Grid.Row stretched centered>
            <Grid.Column largeScreen={6} computer={6} tablet={8}>
              <SelectIconCard
                icon="users"
                onClick={() => onChange('studio-visit' ?? '')}
                selected={
                  !!state.answers.find((c) => c.value === 'studio-visit')
                }
                text="Visit A Studio"
              >
                <p className="gray no-margin">
                  See our kitchens in action at one of our Studios in New York,
                  San Francisco, or Denver. Book an appointment.
                </p>
              </SelectIconCard>
            </Grid.Column>
            <Grid.Column largeScreen={6} computer={6} tablet={8}>
              <SelectIconCard
                icon="video-camera"
                onClick={() => onChange('online-book' ?? '')}
                selected={
                  !!state.answers.find((c) => c.value === 'online-book')
                }
                text="Chat Online"
              >
                <p className="gray no-margin">
                  Chat with a FORM Studio Manager to ask questions and learn
                  more. Book a virtual call.
                </p>
              </SelectIconCard>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row centered style={{ marginTop: '40px' }}>
            <Grid.Column largeScreen={8} computer={8} tablet={8}>
              <h5 className="gray no-margin" style={{ textAlign: 'center' }}>
                Are you ready to start designing?
              </h5>
              <h6
                style={{ cursor: 'pointer', textAlign: 'center' }}
                className="gray no-margin"
                onClick={() => {
                  onChange('start-project' ?? '')
                  navigateWithQS('/welcome/new/project-info/your-details')
                }}
              >
                Take me to the Design Package →
              </h6>
            </Grid.Column>
          </Grid.Row>
        </StyledGrid>
      </Container>
    </>
  )
}

export default GetStartedView

const StyledGrid = styled(Grid)`
  &&&&&&&& {
    @media ${Breakpoint.onlyMobile} {
      margin: 10px 0;
      .row {
        margin-bottom: 20px !important;
        row-gap: 24px !important;
      }
    }
    .row {
      margin-bottom: 50px;
      row-gap: 16px;
      div.column {
        padding-bottom: 0 !important;
        padding-top: 0 !important;
      }
    }
  }
`
