import { useStaticQuery, graphql } from 'gatsby'

export const useMap = () => {
  const { content } = useStaticQuery(graphql`
    query {
      content: allStaticMap {
        data: edges {
          node {
            id
            mapUrl
            childFile {
              childImageSharp {
                gatsbyImageData(layout: CONSTRAINED, width: 750, formats: [PNG])
              }
            }
          }
        }
      }
    }
  `)
  return content
}

export default useMap
